import * as types from '../actions/actionTypes';


export default function GetCampaignReducer(initialState = null, action) {
  switch (action.type) {

    case types.getCampaign:
      return action.payload

    // case types.deleteSubscriber:
    //   console.log(initialState , '00000000',action.payload)
    //   return initialState = {
    //     count: initialState.count - 1,
    //     data: initialState.data.filter(user => user._id !== action.payload._id)
    //   }
    default:
      return initialState;
  }
}