import * as types from '../actions/actionTypes';


export default function LoginReducer(initialState = null, action) {
  switch (action.type) {
    case types.Login:
      return action.payload;
            
    default:
      return initialState;
  }
}