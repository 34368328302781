import * as types from '../actions/actionTypes';


export default function GetAudioReducer(initialState = null, action) {
  switch (action.type) {

    case types.GetAudio:
      return action.payload

    case types.DeleteAudio:
      return initialState = {
        count: initialState.count - 1,
        data: initialState.data.filter(user => user._id !== action.payload)
      }
    default:
      return initialState;
  }
}