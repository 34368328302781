import * as types from '../actions/actionTypes';


export default function viewGroupReducer(initialState = null, action) {
  switch (action.type) {
    case types.viewGroup:
      return action.payload;        
    default:
      return initialState;
  }
}