import { combineReducers } from "redux";
import LoginReducer from './loginreducer';
import {reducer as toastrReducer} from 'react-redux-toastr'
import ForgetpasswordReducer from "./forgetPassword";
import ResetPasswordReducer from "./resetPassword";
import LoadingReducer from "./loading";
import IsLoggedInReducer from './isLoggedinreducer';
import ProfileReducer from "./profilereducer";
import AddSubscriberReducer from './addSubscriber';
import GetSubscriberReducer from "./getSubscriber";
import uploadCSVReducer from './uploadCSV';
import GetParticularSubscriberReducer from "./getParticularSubscriber";
import EditSubscriberReducer from "./editSubscriber";
import SMSCampaignReducer from "./createSMSCampaign";
import GetCampaignReducer from "./getCampaign";
import GetCampaignDataReducer from './getCampaignData';
import MMSCampaignReducer from "./createMMSCampaign";
import DashboardReducer from "./dashboard";
import searchSubscriberReducer from "./searchSubscriber";
import createGroupReducer from "./createGroup";
import groupReducer from "./groupReducer";
import viewGroupReducer from "./viewGroup";
import getAllGroupDataReducer from './getAllGroup';
import EditGroupReducer from "./editGroup";
import AddAudioReducer from "./addAudioReducer";
import GetAudioReducer from "./getAudioReducer";
import ScheduleSMSCampaignReducer from "./scheduleSMSCamapaign";
import SignUpReducer from "./signupReducer";
import CheckOtpReducer from "./checkOtpReducer";
import AddTemplateReducer from "./addTemplateReducer";
import GetTemplateReducer from "./getTemplateReducer";
import GetTemplateDataReducer from "./getTemplatedata";
import editTemplateReducer from "./updateTemplate";
import getAllTemplateReducer from "./allTemplateReducer";
import ScheduleMMSCampaignReducer from "./scheduleMMSReducer";
import getAllPlansReducer from "./getAllPlansReducer";
import AddToGroupReducer from "./addTogroupReducer";
import DeleteGroupMemberResultReducer from "./deleteGroupMemberResultReducer";
import smsEditCamapaignReducer from "./smsEditCamapaignReducer";
import CreditDetailsReducer from "./creditDetailsReducer";
import GetMessagesReducer from "./getMessagesReducer";
import GetIndependentMessageReducer from "./getIndependentMessageReducer";
import availableNumberReducer from "./availableNumberReducer";
import CheckAvailableNumberReducer from "./CheckAvailableNumberReducer";
import chooseNumberReducer from "./chooseNumberReducer";
import SendMessageReducer from "./SendMessageReducer";
import PaymentReducer from "./paymentReducer";

const rootReducer = combineReducers({
   loginR : LoginReducer,
   toastr : toastrReducer,
   forgetPasswordR : ForgetpasswordReducer,
   resetpasswordR : ResetPasswordReducer,
   loadingR : LoadingReducer,
   isLoggedInR : IsLoggedInReducer,
   profileR : ProfileReducer,
   addSubscriberR : AddSubscriberReducer,
   getSubscriberR : GetSubscriberReducer,
   uploadCSVR :uploadCSVReducer,
   getParticularSubscriberR : GetParticularSubscriberReducer,
   editSubscriberR : EditSubscriberReducer,
   SMScampaignR : SMSCampaignReducer,
   MMSCampaignR : MMSCampaignReducer,
   getcampaignR : GetCampaignReducer,
   getCampaignDataR : GetCampaignDataReducer,
   getDashboardDataR : DashboardReducer,
   searchSubscriberR : searchSubscriberReducer,
   createGroupR : createGroupReducer,
   getGroupR :groupReducer,
   viewGroupR : viewGroupReducer,
   getAllGroupDataR : getAllGroupDataReducer,
   editGroupR : EditGroupReducer,
   addAudioR : AddAudioReducer,
   getAudioR : GetAudioReducer,
   scheduleSmsR : ScheduleSMSCampaignReducer,
   signupR : SignUpReducer,
   CheckOtpR : CheckOtpReducer,
   addTemplateR : AddTemplateReducer,
   getTemplateR : GetTemplateReducer,
   getTemplateDataR : GetTemplateDataReducer,
   updateTemplateR : editTemplateReducer,
   getAllTemplateR : getAllTemplateReducer,
   ScheduleMMSCampaignR : ScheduleMMSCampaignReducer,
   getPlanR : getAllPlansReducer,
   addToGroupR : AddToGroupReducer,
   deletegroupMemberR : DeleteGroupMemberResultReducer,
   editMessageR:smsEditCamapaignReducer,
   remainingCreditsR : CreditDetailsReducer,
   GetMessagesR : GetMessagesReducer,
   GetIndependentMessageR : GetIndependentMessageReducer,
   AvailableNumberR : availableNumberReducer,
   CheckAvailableNumberR : CheckAvailableNumberReducer,
   chooseNumberR : chooseNumberReducer,
   SendMessageR : SendMessageReducer,
   paymentR : PaymentReducer
});

export default rootReducer;