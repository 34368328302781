import * as types from '../actions/actionTypes';


export default function ForgetpasswordReducer(initialState = null, action) {
  switch (action.type) {

    case types.forgetPassword:
            return action.payload
    default:
      return initialState;
  }
}