import * as types from '../actions/actionTypes';


export default function GetTemplateDataReducer(initialState = null, action) {
  switch (action.type) {
    case types.getTemplateData:
      return action.payload;        
    default:
      return initialState;
  }
}