import * as types from '../actions/actionTypes';


export default function SMSCampaignReducer(initialState = null, action) {
  switch (action.type) {

    case types.createSMSCampaign:
            return action.payload
    default:
      return initialState;
  }
}