import * as types from '../actions/actionTypes';


export default function SignUpReducer(initialState = null, action) {
  switch (action.type) {
    case types.SignUp:
      return action.payload;
            
    default:
      return initialState;
  }
}