import 'react-app-polyfill/ie9'; // For IE 9-11 support
import 'react-app-polyfill/stable';
// import 'react-app-polyfill/ie11'; // For IE 11 support
import './polyfill'
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom'
import configureStore from './store/configureStore';
import ReduxToastr from 'react-redux-toastr';
const store = configureStore();
ReactDOM.render(
    <Provider store={store}>
        <Router >
            <App />
            <ReduxToastr
                timeOut={7000}
                newestOnTop={false}
                preventDuplicates
                position="top-right"
                transitionIn="fadeIn"
                transitionOut="fadeOut"
                progressBar
                closeOnToastrClick />
        </Router>
    </Provider>, document.getElementById('root'));

serviceWorker.unregister();
