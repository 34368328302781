export const Login = 'Login';
export const AddUser = 'AddUser';
export const getDashboardData = 'getDashboardData';
export const isLoggedIn = 'isLoggedIn';
export const getCompany = 'getCompany';
export const deleteCompany = 'deleteCompany';
export const getParticularCompany = 'getParticularCompany';
export const UpdateCompany = 'UpdateCompany';
export const forgetPassword = 'forgetPassword';
export const resetPassword = 'resetPassword';
export const ViewCompany = 'ViewCompany';
export const getProfile = 'getProfile';
export const Status = 'Status';
export const Loading = 'Loading';
export const addSubscriber = 'addSubscriber';
export const getSubscriber = 'getSubscriber';
export const uploadCSV = 'uploadCSV';
export const GetParticularSubscriber = 'GetParticularSubscriber';
export const EditSubscriber = 'EditSubscriber';
export const deleteSubscriber = 'deleteSubscriber';
export const createSMSCampaign = 'createSMSCampaign';
export const getCampaign = 'getCampaign';
export const getCampaignData = 'getCampaignData';
export const createMMSCampaign = 'createMMSCampaign';  
export const searchSubscriber = 'searchSubscriber';
export const createGroup = 'createGroup';
export const GroupData = 'GroupData';
export const viewGroup = 'viewGroup';
export const getAllGroup = 'getAllGroup';
export const editGroup = 'editGroup';
export const AddAudio = 'AddAudio';
export const GetAudio = 'GetAudio';
export const DeleteAudio = 'DeleteAudio';
export const scheduleSMSCamapaign = 'scheduleSMSCamapaign';
export const schedulemmsCampaign = 'schedulemmsCampaign';
export const SignUp = 'SignUp';
export const checkOtp = 'checkOtp';
export const AddTemplate = 'AddTemplate';
export const getTemplates = 'getTemplates';
export const deleteTemplate = 'deleteTemplate';
export const getTemplateData = 'getTemplateData';
export const UpdateTemplateData = 'UpdateTemplateData';
export const getAllTemplate = 'getAllTemplate';
export const getAllPlans = 'getAllPlans';
export const addTogroup = 'addTogroup';
export const deleteGroup = 'deleteGroup';
export const deleteGroupMemberResult = 'deleteGroupMemberResult';
export const smsEditCamapaign = 'smsEditCamapaign';
export const CreditDetails = 'CreditDetails';
export const getMessages = "getMessages";
export const getIndependentMessage = "getIndependentMessage";
export const AvailableNo = "AvailableNo";
export const CheckAvailableNumber = "CheckAvailableNumber";
export const chooseNumber = "chooseNumber";
export const SendMessage = "SendMessage";
export const Payment = "Payment";
// console.log("enter in action")


