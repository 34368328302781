import * as types from '../actions/actionTypes';


export default function groupReducer(initialState = null, action) {
  switch (action.type) {
    case types.GroupData:
      return action.payload;     
      
      case types.deleteGroup:
        return initialState = {
          count: initialState.count - 1,
          data: initialState.data.filter(user => user._id !== action.payload)
        }
    default:
      return initialState;
  }
}