import * as types from '../actions/actionTypes';


export default function AddAudioReducer(initialState = null, action) {
  switch (action.type) {

    case types.AddAudio:
            return action.payload
    default:
      return initialState;
  }
}