import React from "react";
import { Redirect, Route } from "react-router-dom";
const DefaultLayout = React.lazy(() => import('../containers/DefaultLayout'));

export const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={props =>
            localStorage.getItem('loginDataBussinessUser') ?
                (<DefaultLayout {...props} />) : (
                    <Redirect to={{
                        pathname: 'login',
                        state: { from: props.location }
                    }}
                    />
                )} />
)

export default PrivateRoute;
