import * as types from '../actions/actionTypes';


export default function CheckOtpReducer(initialState = null, action) {
  switch (action.type) {
    case types.checkOtp:
      return action.payload;
            
    default:
      return initialState;
  }
}