import * as types from '../actions/actionTypes';

export default function getAllGroupDataReducer(initialState = null, action) {
  switch (action.type) {

    case types.getAllGroup:
            return action.payload
    default:
      return initialState;
  }
}